import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ConfirmCodeModal from "../../ConfirmCodeModal";

function Registration() {

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [phoneConfirmed, setPhoneConfirmed] = useState(false);
  const [showCodeRequest, setShowCodeRequest] = useState(false);

  const inputFields = {
    email: '',
    password: '',
    passwordConfirmation: '',
    f: '',
    i: '',
    o: '',
    phoneNumber: '',
  }

  const [formData, setFormData] = useState({
    userType: '0',
    ...inputFields
  });
  const [formError, setFormError] = useState(inputFields);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [validationResult, setValidationResult] = useState(inputFields)

  const validator = {
    emailValidator: () => {
      let re = /\S+@\S+\.\S+/;
      if (!re.test(formData.email)) {
        setFormError({...formError, email: 'Укажите email соответствующий формату'});
        setValidationResult({...validationResult, email: 'is-invalid'});
        return false;
      } else {
        setFormError({...formError, email: ""});
        setValidationResult({...validationResult, email: 'is-valid'});
        return true;
      }
    },
    passwordValidator: () => {
      let passwordConfirmation = '';
      let pwValidationResult = '';

      if (formData.passwordConfirmation && formData.password !== formData.passwordConfirmation) {
        passwordConfirmation = 'Пароль не совпадает с подтверждением';
        pwValidationResult = 'is-invalid';
      }

      if (formData.passwordConfirmation && formData.password === formData.passwordConfirmation) {
        pwValidationResult = 'is-valid';
      }

      if (formData.password.length > 5) {
        setFormError({...formError, password: '', passwordConfirmation: passwordConfirmation});
        setValidationResult({...validationResult, password: 'is-valid', passwordConfirmation: pwValidationResult});
        return true;
      } else {
        setFormError({...formError, password: 'Минимум 6 символов', passwordConfirmation: passwordConfirmation});
        setValidationResult({...validationResult, password: 'is-invalid', passwordConfirmation: pwValidationResult});
        return false;
      }
    },
    passwordConfirmationValidator: () => {
      if (formData.passwordConfirmation) {
        if (formData.password !== formData.passwordConfirmation) {
          setFormError({...formError, passwordConfirmation: 'Пароль не совпадает с подтверждением'});
          setValidationResult({...validationResult, passwordConfirmation: 'is-invalid'});
          return false;
        } else {
          setFormError({...formError, passwordConfirmation: ""});
          setValidationResult({...validationResult, passwordConfirmation: 'is-valid'});
          return true;
        }
      }
    },
    phoneValidator: () => {
      let normalizedPhone = formData.phoneNumber.replace(/\D/g, '');

      /** Проверим что указан телефон полностью, отправим смс и выводим окно для ввода кода */
      if (normalizedPhone.length < 11) {
        setFormError({...formError, phoneNumber: 'Укажите телефон полностью'});
        setValidationResult({...validationResult, phoneNumber: 'is-invalid'});
        return false;
      }
    },
    nameValidator: () => {
      if (formData.i) {
        setFormError({...formError, i: ''})
        setValidationResult({...validationResult, i: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, i: 'Укажите как вам обращаться'})
        setValidationResult({...validationResult, i: 'is-invalid'});
        return false;
      }
    },
    lastNameValidator: () => {
      if (formData.f) {
        setFormError({...formError, f: ''})
        setValidationResult({...validationResult, f: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, f: 'Укажите как вам обращаться'})
        setValidationResult({...validationResult, f: 'is-invalid'});
        return false;
      }
    }
  }

  useEffect(() => {
    setPhoneConfirmed(false);
  }, [formData.phoneNumber])

  /** Убираем лишние символы (+,7,8) из начала телефона при копипасте, так как у нас используется маска */
  const onPastePhone = (e) => pastePhone(e, phone => setFormData({...formData, phoneNumber: '+7' + phone}))

  /** Валидация и отправка формы */
  const handleSubmit = () => {
    let result = true;
    let errorMessages = {};
    let validClasses = {};

    let re = /\S+@\S+\.\S+/;
    if (!re.test(formData.email)) {
      errorMessages.email = 'Укажите email соответствующий формату';
      validClasses.email = 'is-invalid';
      result = false;
    }

    if (formData.password.length < 6) {
      errorMessages.password = 'Минимальная длинна пароля 6 символов';
      validClasses.password = 'is-invalid';
      result = false;
    }

    if (formData.password !== formData.passwordConfirmation) {
      errorMessages.passwordConfirmation = 'Пароль не совпадает с подтверждением';
      validClasses.passwordConfirmation = 'is-invalid';
      result = false;
    }

    if (!formData.i) {
      errorMessages.i = 'Укажите как вам обращаться';
      validClasses.i = 'is-invalid';
      result = false;
    }

    if (!formData.f) {
      errorMessages.f = 'Укажите фамилию';
      validClasses.f = 'is-invalid';
      result = false;
    }

    let normalizedPhone = formData.phoneNumber.replace(/\D/g, '');
    /** Проверим что указан телефон полностью, отправим смс и выводим окно для ввода кода */
    if (normalizedPhone.length < 11) {
      errorMessages.phoneNumber = 'Укажите телефон полностью';
      validClasses.phoneNumber = 'is-invalid';
      result = false;
    } else if (!phoneConfirmed) {
      errorMessages.phoneNumber = 'Подтвердите телефон';
      validClasses.phoneNumber = 'is-invalid';
      result = false;
    }

    setFormError({...formError, ...errorMessages});
    setValidationResult({...validationResult, ...validClasses});

    if (result) {
      setFormError(inputFields);
      setLoading(true);

      /** Нормализуем телефон перед отправкой */
      const data = Object.assign({}, formData);
      data.phoneNumber = data.phoneNumber.replace(/\D/g, '');

      register(data)
        .then((result) => {
            history.push('/auth/registration/success');
        })
        .catch((e) => {
          if (e.response) {
            const {data} = e.response;
            Object.keys(data).map((key) => {
              setFormErrorMessage(data[key]);
            });
          } else {
            setFormErrorMessage('Что то пошло не так');
          }
          setLoading(false);
          console.log(e);
        });
    } else {
      setFormErrorMessage('Исправьте ошибки и заполните все поля');
    }
  }

  /** Запрос кода в смс для подтверждения телефона */
  const onClickPhoneConfirm = () => {
    let normalizedPhone = formData.phoneNumber.replace(/\D/g, '');

    /** Проверим что указан телефон полностью, отправим смс и выводим окно для ввода кода */
    if (normalizedPhone.length < 11) {
      setFormError({...formError, phoneNumber: 'Укажите телефон полностью'});
      setValidationResult({...validationResult, phoneNumber: 'is-invalid'});
    } else {
      let postData = {
        phone: normalizedPhone,
      };

      axios.post("/Account/PhoneConfirmation", postData)
        .then((response) => {
          if (response.status === 200) {
            setShowCodeRequest(true);
            setFormError({...formError, phoneNumber: ''});
          }
        }).catch((e) => {
          setFormError({...formError, phoneNumber: 'Подождите 30 секунд перед новый запросом кода'});
      })
    }
  }

  /** Код из смс введен верно */
  const onCodeConfirmedHandler = () => {
    setFormError({...formError, phoneNumber: ''});
    setValidationResult({...validationResult, phoneNumber: 'is-valid'});
    setPhoneConfirmed(true);
    setShowCodeRequest(false);
  }

  return (
    <>
      <div className="d-flex flex-column-fluid flex-center mt-xs-30 mt-10 mt-lg-0">
        <div className="wizard wizard-3">
          <div className="row justify-content-center py-10 px-8 py-lg-12 px-lg-10">
            <div className="col-xl-12 col-xxl-7">
              {/* begin::Head */}
              <div className="text-center mb-5 mb-lg-5">
                <h3 className="font-size-h1">
                  Регистрация
                </h3>
                <p className="text-muted font-weight-bold">
                  Заполните объязательные поля
                </p>
              </div>
              {/* end::Head */}
              <div className="text-center mb-xs-15 flex-column-auto justify-content-center py-5">
                <span className="font-weight-bold text-dark-50">
                  Уже есть аккаунт?
                </span>
                <Link
                  to="/auth/login"
                  className="font-weight-bold ml-2"
                  id="kt_login_signup"
                >
                  Авторизоваться!
                </Link>
              </div>
              <form
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={handleSubmit}
              >
                {formErrorMessage.length > 0 && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{formErrorMessage}</div>
                  </div>
                )}

                <div className="pb-5">
                  <div className="form-group">
                    <label>Email *</label>
                    <input
                      type="text"
                      className={`form-control ${validationResult.email}`}
                      name="email"
                      placeholder="example@example.com"
                      value={formData.email}
                      onChange={(e) => setFormData({...formData, email: e.target.value})}
                      onBlur={() => validator.emailValidator()}
                    />
                    <span className="form-text text-muted">Укажите ваш email.</span>
                    {formData.email && formError.email  ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formError.email}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>Номер телефона*</label>
                    <div className="input-group">
                      <InputMask
                        mask="+7 (999) 999-9999"
                        placeholder="Телефон"
                        type="text"
                        name="phone"
                        onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})}
                        onPaste={onPastePhone}
                        onBlur={validator.phoneValidator}
                        value={formData.phoneNumber}
                        alwaysShowMask={true}
                        className={`form-control ${validationResult.phoneNumber}`}
                      />
                      {
                        !phoneConfirmed &&
                          <div className="input-group-append">
                            <button onClick={onClickPhoneConfirm} className="btn btn-primary" type="button">Подтвердить
                            </button>
                          </div>
                      }
                    </div>
                    {formData.phoneNumber && formError.phoneNumber ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formError.phoneNumber}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>Пароль *</label>
                    <input
                      type="password"
                      className={`form-control ${validationResult.password}`}
                      name="password"
                      placeholder="qwerty123"
                      value={formData.password}
                      onChange={(e) => setFormData({...formData, password: e.target.value})}
                      onBlur={() => validator.passwordValidator()}
                    />
                    <span className="form-text text-muted">Укажите желаемый пароль.</span>
                    {formData.password && formError.password  ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formError.password}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>Подтверждение пароля *</label>
                    <input
                      type="password"
                      className={`form-control ${validationResult.passwordConfirmation}`}
                      name="passwordConfirmation"
                      placeholder="qwerty321"
                      value={formData.passwordConfirmation}
                      onChange={(e) => setFormData({...formData, passwordConfirmation: e.target.value})}
                      onBlur={() => validator.passwordConfirmationValidator()}
                    />
                    <span className="form-text text-muted">И еще разок.</span>
                    {formData.passwordConfirmation && formError.passwordConfirmation  ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formError.passwordConfirmation}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>Фамилия *</label>
                    <input
                      type="text"
                      className={`form-control ${validationResult.f}`}
                      name="f"
                      placeholder="Иванов"
                      value={formData.f}
                      onChange={(e) => setFormData({...formData, f: e.target.value})}
                      onBlur={validator.lastNameValidator}
                    />
                    {formError.f ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formError.f}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>Имя *</label>
                    <input
                      type="text"
                      className={`form-control ${validationResult.i}`}
                      name="i"
                      placeholder="Иван"
                      value={formData.i}
                      onChange={(e) => setFormData({...formData, i: e.target.value})}
                      onBlur={validator.nameValidator}
                    />
                    {formError.i ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formError.i}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>Отчество</label>
                    <input
                      type="text"
                      className={`form-control ${validationResult.o}`}
                      name="o"
                      placeholder="Иванович"
                      value={formData.o}
                      onChange={(e) => setFormData({...formData, o: e.target.value})}
                    />
                  </div>

                </div>


                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                  <div className="mr-2">
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      <span>Зарегистрироваться</span>
                      {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {
        showCodeRequest ?
          <ConfirmCodeModal
            onCodeConfirmed={onCodeConfirmedHandler}
            onHide={() => setShowCodeRequest(false)}
            phone={formData.phoneNumber.replace(/\D/g, '')}
          /> : ''
      }
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(Registration));

/** Убираем лишние символы (+,7,8) из начала телефона при копипасте, так как у нас используется маска */
export const pastePhone = (e, callback) => {
  e.preventDefault();
  let phone = (e.clipboardData || window.clipboardData).getData('Text');

  if (phone.charAt(0) === '+') {
    phone = phone.substring(1);
  }

  if (phone.charAt(0) === '7' || phone.charAt(0) === '8') {
    phone = phone.substring(1);
  }

  callback(phone);
}