import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Не правильный формат email")
      .min(3, "Минимум 3 символа")
      .max(50, "Максимум 50 символов")
      .required('Укажите email'),
    password: Yup.string()
      .min(3, "Минимум 3 символа")
      .max(50, "Максимум 50 символов")
      .required('Укажите пароль'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              disableLoading();
              props.login(data.token);
            }
          })
          .catch(e => {
            let data = e.response?.data;
            let errors = '';

            if (data && data.length > 0) {
              // eslint-disable-next-line
              Object.keys(data).map((key, index) => {
                errors += data[key][0] + '\n';
              });
            } else {
              errors = 'Email или пароль неверные';
            }

            disableLoading();
            setSubmitting(false);
            setStatus(errors);
          });
      }, 1000);
    },
  });

  return (
    <>
      <div className="d-flex flex-column-fluid flex-center mt-xs-30 mt-10 mt-lg-0">
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              Вход в личный кабинет
            </h3>
            <p className="text-muted font-weight-bold">
              Укажите ваш email и пароль
            </p>
          </div>
          {/* end::Head */}

          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {
              formik.status ?
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div> : ''
            }
            <div className="mb-xs-15 mb-lg-0 flex-column-auto justify-content-left py-5">
              <span className="font-weight-bold text-dark-50">
                Еще нет аккаунта?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Зарегистрироваться!
              </Link>
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Пароль"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <Link
                to="/auth/forgot-password"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="kt_login_forgot"
              >
                Забыли пароль?
              </Link>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Вход</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
