import {createSlice} from "@reduxjs/toolkit";

const initialManagerState = {
  name: 'Константин Воронов',
  phone: '+79857677374',
  email: '7677374@gmail.com'
}

export const managerSlice = createSlice({
  name: "manager",
  initialState: initialManagerState,
  reducers: {
    updateManager: (state, action) => {
      console.log(action.payload);
      state.name = action.payload.name;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
    },
  }
});