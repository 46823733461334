import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import {useToasts} from "react-toast-notifications";

export default function PasswordRestoration(props) {

  const {addToast} = useToasts();

  const initialValues = {
    token: props.token,
    email: props.email,
    code: "",
    password: "",
    passwordConfirmation: ""
  };

  const [isValid, setIsValid] = useState(false);

  const PasswordRestorationSchema = Yup.object().shape({
    code: Yup.string()
      .min(6, "Код должен быть 6 цифр")
      .max(6, "Код должен быть 6 цифр")
      .required('Укажите код отправленный на email'),
    email: Yup.string()
      .email("Не правильный формат email")
      .min(3, "Минимум 3 символа")
      .max(50, "Максимум 50 символов")
      .required('Укажите ваш email'),
    password: Yup.string()
      .min(6, "Минимум 6 символов")
      .max(50, "Максимум 50 символов")
      .required('Укажите новый пароль'),
    passwordConfirmation: Yup.string()
      .required('Укажите новый пароль еще раз')
      .when("password", {
        is: (val) => (!!(val && val.length > 0)),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Пароли не совпадают"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordRestorationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      axios.post("Account/ResetPassword", values)
        .then(() => {
          addToast(
            `Пароль успшено изменен`,
            {
              appearance: 'success',
              autoDismissTimeout: 3000,
              autoDismiss: true
            });
          setTimeout(() => setIsValid(true), 3000);
        })
        .catch(e => {
          console.log(e);
          setIsValid(false);
          setSubmitting(false);
          setStatus(
            `Что то пошло не так`
          );
        })
      /*requestPassword(values.email)
        .then(() => setIsValid(true))
        .catch((e) => {
          console.log(e);
          setIsValid(false);
          setSubmitting(false);
          setStatus(
            `Что то пошло не так`
          );
        });*/
    },
  });

  return (
    <div className="d-flex flex-column-fluid flex-center mt-xs-30 mt-10 mt-lg-0">
      {
        isValid && <Redirect to={"/auth"} />
      }
      {(
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Восстановление пароля</h3>
            <div className="text-muted font-weight-bold">
              Укажите код отправленный на ваш email для восстановления пароля
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                placeholder={"Email"}
                defaultValue={props.email}
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "code"
                )}`}
                name="code"
                placeholder={"Код"}
                {...formik.getFieldProps("code")}
              />
              {formik.touched.code && formik.errors.code ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.code}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Пароль"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Пароль"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "passwordConfirmation"
                )}`}
                name="passwordConfirmation"
                {...formik.getFieldProps("passwordConfirmation")}
              />
              {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.passwordConfirmation}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Отправить
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Отмена
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
