import React, {Suspense, lazy, useEffect} from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "./modules/Basket/redux/basketActions";
import {Modal} from "react-bootstrap";
import {hidePartnerAddForm} from "./modules/Partners/redux/parnerActions";
import PartnersAddForm from "./modules/Partners/PartnersAddForm";

const CatalogPage = lazy(() =>
  import("./modules/Catalog/Catalog")
);
const DeliveryPage = lazy(() =>
  import("./pages/DeliveryPage")
);
const OrderPage = lazy(() =>
    import("./pages/OrderPage")
);
const CartPage = lazy(() =>
  import("./pages/CartPage")
);
const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const ContactPage = lazy(() =>
  import("./pages/ContactPage")
);
const AdminPage = lazy(() =>
  import("./modules/Admin/AdminPage")
);

export default function BasePage() {

  const dispatch = useDispatch();
  const location = useLocation();
  const {showAddForm} = useSelector(state => state.partner);

  useEffect(() => {
    dispatch(actions.getBasketCount());
    //eslint-disable-next-line
  }, [location]);

  const onHideModalHandler = () => {
    dispatch(hidePartnerAddForm());
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/catalog" />
          }
          <ContentRoute path="/catalog" component={CatalogPage} />
          <ContentRoute path="/delivery" component={DeliveryPage} />
          <ContentRoute path="/cart" component={CartPage} />
          <ContentRoute path="/order" component={OrderPage} />
          <ContentRoute path="/contacts" component={ContactPage} />
          <Route path="/profile" component={UserProfilePage} />
          <Route path="/admin" component={AdminPage} />
          <Redirect to="error/error-v1" />
        </Switch>
        <Modal
          size="lg"
          show={showAddForm}
          onHide={onHideModalHandler}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Завершение регистрации</Modal.Title>
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <PartnersAddForm lightForm={true} onHide={onHideModalHandler} reloadPage={true} showAlert={true} />
          </Modal.Body>
        </Modal>
      </>
    </Suspense>
  );
}
