import {Link} from "react-router-dom";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import * as axios from "axios";

export default function RegistrationConfirm() {

  const email = useSelector(({ auth }) => auth.user?.email ?? '');

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');

  const sendEmailHandler = () => {
    setLoading(true);

    axios.post("/Account/EmailConfirmation", {email: email})
      .then(() => {
        setEmailSent(true);
        setTimeout(() => {
          setEmailSent(false);
        }, 60000);
        setLoading(false);
      }).catch(() => {
        setError("Не так часто, повторная отправка будет доступна через минуту");
        setLoading(false);
    })
  }

  return (
    <>
      {/*begin::Content header*/}
      <div className="position-absolute top-0 right-0 text-right mb-xs-15 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">

        </span>
        <Link
          to="/logout"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          Выход
        </Link>
      </div>
      {/*end::Content header*/}

      <div className="d-flex flex-column-fluid flex-center mt-xs-30 mt-10 mt-lg-0">
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              Подтверждение почты
            </h3>
            <p className="text-muted font-weight-bold">
              Вам на почту был отправлен код и ссылка для подтверждения Email. Если проверочное письмо не пришло, попробуйте отправить его еще раз, не забудьте проверить папку "Спам"
            </p>
          </div>
          {/* end::Head */}

          {/*begin::Form*/}
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {
              emailSent ?
                <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                  <div className="alert-text font-weight-bold">Письмо успешно отправлено</div>
                </div> : ''
            }
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="email"
                value={email}
                disabled={true}
              />
              {error ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{error}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <button
                id="kt_login_signin_submit"
                type="button"
                disabled={loading}
                onClick={sendEmailHandler}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Отправить</span>
                {loading && <span className="ml-3 spinner spinner-white"/>}
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </>
  );
}