import {createSlice} from "@reduxjs/toolkit";

const initialPartnerState = {
  needRegister: true,
  showAddForm: false,
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState: initialPartnerState,
  reducers: {
    showAddPartnerForm: (state, action) => {
      state.showAddForm = action.payload
    },
    needRegisterPartner: (state, action) => {
      state.needRegister = action.payload;
      state.showAddForm = action.payload;
    },
  }
});