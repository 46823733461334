import {createSlice} from "@reduxjs/toolkit";

const initialBasketState = {
  count: 0,
  summary: 0
};

export const basketSlice = createSlice({
  name: "basket",
  initialState: initialBasketState,
  reducers: {
    updateBasketCount: (state, action) => {
      state.count = action.payload.count;
      state.summary = action.payload.summary;
    },
  }
});