import {partnerSlice} from "./partnerSlice";

const {actions} = partnerSlice;

export const showPartnerAddForm = () => {
  return actions.showAddPartnerForm(true);
};

export const hidePartnerAddForm = () => {
  return actions.showAddPartnerForm(false);
};

export const needRegisterPartner = (value) => {
  return actions.needRegisterPartner(value);
};