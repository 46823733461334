import {basketSlice} from "./basketSlice";
import * as requestFromServer from "./basketCrud"

const {actions} = basketSlice;

export const getBasketCount = () => dispatch => {
  return requestFromServer
    .basketSummary()
    .then(response => {
      const { count, summary } = response.data;
      dispatch(actions.updateBasketCount({ count, summary }));
    })
    .catch(error => {
      console.log(error);
    });
};

export const updateBasketItem = (productId, offerId, storeId, priceId, quantity) => dispatch => {
  return requestFromServer
    .updateBasketItem(productId, offerId, storeId, priceId, quantity)
    .then(response => {
      const { count, summary } = response.data;
      dispatch(actions.updateBasketCount({ count, summary }));
    })
    .catch(error => {
      console.log(error);
    });
};
