import axios from "axios";

export const LOGIN_URL = "Account/Login";
export const REGISTER_URL = "Account/Register";
export const REQUEST_PASSWORD_URL = "Account/ForgotPassword";

export const ME_URL = "Account/Profile";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(data) {
  return axios.post(REGISTER_URL, data);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
