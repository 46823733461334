/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect, Route} from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import RegistrationSuccess from "./RegistrationSuccess";
import {shallowEqual, useSelector} from "react-redux";
import RegistrationConfirm from "./RegistrationConfirm";
import {Logout} from "../index";
import EmailConfirm from "./EmailConfirm";

export function AuthPage() {
  const { isAuthorized, isEmailConfirmed } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isEmailConfirmed: auth.user?.emailConfirmed
    }),
    shallowEqual
  );

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundColor: "#f4f4f5",
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/images/logo-big.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5">
                  Добро пожаловать!
                </h3>
                <p className="font-weight-lighter opacity-80">
                    <b>RONTA</b> - Предприятие полного цикла! <br/>
                    Все виды работ - от разработки макета, до пошива готового изделия:
                    <br/><br/>
                    <li>Собственное конструкторское бюро.</li>
                    <li>Цех раскройки.</li>
                    <li>Цех пошива</li>
                    <li>Участок маркировки готовой продукции</li>
                    <li>Отдел технического контроля</li>
                    <br/>
                    Сегодня <b>RONTA</b> оказывает полный спектр услуг, от разработки моделей по требованиям и в соотвествии с корпоративным стилем заказчика, до пошива готовых изделий и нанесения на них логотипов и фирменных знаков предприятия-заказчика!<br/>
                    <br/>
                    <li><b>Гибкая ценовая политика</b> - Мы дорожим своей репутацией, поэтому стараемся использовать только качественные комплектующие, с учетом пожеланий заказчика.</li>
                    <li><b>Клиентоориентированность</b> - Мы постоянно работаем над улучшением и модернизацией потребительских свой нашей продукции, следим за потребностями рынка - наша цель, довольный клиент.</li>
                    <li><b>Согласование с заказчиком</b> - На всех этапах производства заказчику предоставляются отчеты и своевремнно вносятся коррективы.</li>
                    <li><b>Контроль качества</b> - Наша продукция, контролируется всех этапах производства, готовые изделия подлежащие маркировке вносятся в "Национальный каталог товаров".</li>
                    <br/>
                    <br/>
                    <b>
                      <p><a href="mailto:info@ronta.ru">info@ronta.ru</a></p>
                      <p><a href="tel:88007776503">8(800)777-65-03</a></p>
                      <p><a href="tel:+74951070200">+7(495)107-02-00</a></p>
                    </b>
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold">
                  &copy; 2024 RONTA
                </div>
                {/*
                  <div className="d-flex">
                    <Link to="/terms" className="text-white">
                      Privacy
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Legal
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Contact
                    </Link>
                  </div>
                */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            {/* begin::Content body */}
            <Switch>
                <Route path="/logout" component={Logout}/>
                <ContentRoute
                  path="/auth/confirm/:email/:code"
                  component={EmailConfirm}
                />
                <ContentRoute
                  path="/auth/registration/confirm"
                  component={RegistrationConfirm}
                />
                {
                  isAuthorized && !isEmailConfirmed ?
                    <>
                      <Redirect to="/auth/registration/confirm"/>
                    </> : ''
                }
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration/success"
                  component={RegistrationSuccess}
                />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login"/>
              </Switch>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2024 RONTA
              </div>
              {/*
                <div className="d-flex order-1 order-sm-2 my-2">
                  <Link to="/terms" className="text-dark-75 text-hover-primary">
                    Privacy
                  </Link>
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Legal
                  </Link>
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Contact
                  </Link>
                </div>
              */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
