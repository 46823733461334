/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import {Link} from "react-router-dom";

export function QuickUserToggler() {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="quick-user-tooltip">Профиль пользователя</Tooltip>}
      >
        <div className="topbar-item">
          <Link
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            to={'/profile'}
          >
            <>
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                Привет,
              </span>
              <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                {user.i}
              </span>
              <span className="symbol symbol-35 symbol-light-success">
                <span className="symbol-label font-size-h5 font-weight-bold">
                  {user.i[0]}
                </span>
              </span>
            </>
          </Link>
        </div>
      </OverlayTrigger>
    </>
  );
}
