import React, {useEffect, useState} from "react";
import Autosuggestion from "react-autosuggest";
import axios from "axios";

export default function SearchHelper(props) {

  const [suggestions, setSuggestions] = useState([]);
  const [timer, setTimer] = useState(null);
  const [value, setValue] = useState(props.value ?? '');

  useEffect(() => {
    if (props?.value) {
      setValue(props.value);
    }
  }, [props]);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    loadSuggestions(value);
  };

  const loadSuggestions = (query) => {
    if (query.length > 2) {
      clearTimeout(timer);

      let t = setTimeout(() => {
        axios.post(props.url,
          {
            count: 5,
            query: query,
            status: ["ACTIVE"]
          },
        {
            headers: {
              'authorization': 'token 199bc0b22b0c5ca94bc3753ce866880f491228b5'
            }
          }
        )
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              setSuggestions(data.suggestions);
            }
          }).catch((e) => console.log(e));
      }, 300);
      setTimer(t);
    }
  }

  const onSuggestionSelected = (event, {suggestion}) => {
    setValue(suggestion.value);
    props.onSelectSuggestion(suggestion);
  }

  return (
    <Autosuggestion
      suggestions={suggestions}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={onSuggestionSelected}
      focusInputOnSuggestionClick={false}
      shouldRenderSuggestions={(value, reason) => {
        //if (reason === 'input-focused' && streetInfo !== null) {
        //  return false;
        //}
        return value.trim().length > 2;
      }}
      inputProps={{
        placeholder: props.placeholder,
        value: value,
        onChange: (e) => setValue(e.target.value),
        autocomplete: "chrome-off",
        className: props.className,
        disabled: props.disabled,
      }}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
    />
  );
}

function renderSuggestion(suggestion) {
  return (
    <>
      <span>{suggestion.value}</span>
      <span className="details">{suggestion.data.bic ? `БИК: ${suggestion.data.bic}` : suggestion.data.ogrn ? `ОГРН(ИП): ${suggestion.data.ogrn}` : ``}{suggestion.data.inn ? ` ИНН: ${suggestion.data.inn}` : ``}</span>
    </>

  );
}

function getSuggestionValue(suggestion) {
  return suggestion.value;
}