import axios from "axios";

export function addBasketItem(productId, offerId, storeId, priceId, quantity) {
  return axios.get(`ShoppingCart/Add?goods=${productId}&subGoods=${offerId}&storeId=${storeId}&priceType=${priceId}&quantity=${quantity}`);
}

export function updateBasketItem(productId, offerId, storeId, priceId, quantity) {
  return axios.get(`ShoppingCart/Update?goods=${productId}&subGoods=${offerId}&storeId=${storeId}&priceType=${priceId}&quantity=${quantity}`);
}

export function removeBasketItem(productId, offerId, storeId, priceId) {
  return axios.get(`ShoppingCart/Remove?goods=${productId}&subGoods=${offerId}&storeId=${storeId}&priceType=${priceId}`);
}

export function clearBasket() {
  return axios.get(`ShoppingCart/Clear`);
}

export function basketSummary() {
  return axios.get(`ShoppingCart/Summary`);
}

