import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { makeStyles } from '@material-ui/core/styles';
import {Badge} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
}));

export function Topbar() {
  const uiService = useHtmlClassService();
  const classes = useStyles();

  const basket = useSelector(state => state.basket)

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Корзина</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Корзина"
            data-placement="right"
          >
            <Link
              to="/cart"
              className="btn btn-icon btn-clean btn-lg mr-3"
            >
              <Badge className={classes.margin} badgeContent={basket.count} color="secondary" max={999}>
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Cart1.svg"
                    )}
                  />
                </span>
              </Badge>
            </Link>
          </div>
        </OverlayTrigger>
      )}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
