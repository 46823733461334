/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ModalProgressBar} from "../../../_metronic/_partials/controls";
import SearchHelper from "../Auth/pages/SearchHelper";
import axios from "axios";
import { needRegisterPartner } from "./redux/parnerActions";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker"
import ru from 'date-fns/locale/ru';
import InputMask from "react-input-mask";
import {useHistory} from "react-router-dom";

export default function PartnersAddForm(props) {

  registerLocale('ru', ru)

  const history = useHistory();

  const dispatch = useDispatch();
  const inputFields = {
    companyName: '',
    inn: '',
    ogrn: '',
    kpp: '',
    bankName: '',
    bankAddress: '',
    accountNumber: '',
    bik: '',
    corrAccount: '',
    role: '1',
    docNumber: '',
    docDate: ''
  }


  const [loading, setLoading] = useState(false);
  const [manualCompany, setManualCompany] = useState(false);
  const [manualBank, setManualBank] = useState(false);
  const [formData, setFormData] = useState({...inputFields});
  const [formError, setFormError] = useState(inputFields);
  const [formErrorMessage, setFormErrorMessage] = useState([]);
  const [validationResult, setValidationResult] = useState(inputFields)

  const validator = {
    companyNameValidator: () => {
      if (formData.companyName) {
        setFormError({...formError, companyName: ""})
        setValidationResult({...validationResult, companyName: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, companyName: 'Это поле объязательно для заполнения'});
        setValidationResult({...validationResult, companyName: 'is-invalid'});
        return false;
      }
    },
    innValidator: () => {
      if (formData.inn.length !== 12 && formData.inn.length !== 10) {
        setFormError({...formError, inn: 'ИНН должен быть из 10 или 12 цифр'});
        setValidationResult({...validationResult, inn: 'is-invalid'});
        return false;
      } else {
        setFormError({...formError, inn: ""})
        setValidationResult({...validationResult, inn: 'is-valid'});
        return true;
      }
    },
    ogrnValidator: () => {
      if (formData.ogrn.length < 13) {
        setFormError({...formError, ogrn: 'ОГРН или ОГРНИП должны быть 13 и 15 цифр соответсвенно'});
        setValidationResult({...validationResult, ogrn: 'is-invalid'});
        return false;
      } else {
        setFormError({...formError, ogrn: ""})
        setValidationResult({...validationResult, ogrn: 'is-valid'});
        return true;
      }
    },
    bankNameValidator: () => {
      if (formData.bankName) {
        setFormError({...formError, bankName: ""})
        setValidationResult({...validationResult, bankName: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, bankName: 'Поле объязательно для заполнения'});
        setValidationResult({...validationResult, bankName: 'is-invalid'});
        return false;
      }
    },
    bankAddressValidator: () => {
      if (formData.bankAddress) {
        setFormError({...formError, bankAddress: ""})
        setValidationResult({...validationResult, bankAddress: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, bankAddress: 'Поле объязательно для заполнения'});
        setValidationResult({...validationResult, bankAddress: 'is-invalid'});
        return false;
      }
    },
    accountNumberValidator: () => {
      if (formData.accountNumber) {
        setFormError({...formError, accountNumber: ""})
        setValidationResult({...validationResult, accountNumber: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, accountNumber: 'Поле объязательно для заполнения'});
        setValidationResult({...validationResult, accountNumber: 'is-invalid'});
        return false;
      }
    },
    bikValidator: () => {
      if (formData.bik) {
        setFormError({...formError, bik: ""})
        setValidationResult({...validationResult, bik: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, bik: 'Поле объязательно для заполнения'});
        setValidationResult({...validationResult, bik: 'is-invalid'});
        return false;
      }
    },
    corrAccountValidator: () => {
      if (formData.corrAccount) {
        setFormError({...formError, corrAccount: ""})
        setValidationResult({...validationResult, corrAccount: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, corrAccount: 'Поле объязательно для заполнения'});
        setValidationResult({...validationResult, corrAccount: 'is-invalid'});
        return false;
      }
    },
    docNumber: () => {
      if (formData.docNumber) {
        setFormError({...formError, docNumber: ""})
        setValidationResult({...validationResult, docNumber: 'is-valid'});
        return true;
      } else {
        setFormError({...formError, docNumber: 'Поле объязательно для заполнения'});
        setValidationResult({...validationResult, docNumber: 'is-invalid'});
        return false;
      }
    }
  }

  const companySuggestionSelected = (suggestion) => {
    setFormData({
      ...formData,
      companyName: suggestion.data.name.short_with_opf,
      inn: suggestion.data.inn,
      ogrn: suggestion.data.ogrn,
      kpp: suggestion.data.kpp,
    });
    setFormError({
      ...formError,
      companyName: "",
      inn: "",
      ogrn: "",
    });
    setValidationResult({
      companyName: "is-valid",
      inn: "is-valid",
      ogrn: "is-valid",
    });
  }

  const bankSuggestionSelected = (suggestion) => {
    setFormData({
      ...formData,
      bankName: suggestion.data.name.short,
      bankAddress: suggestion.data.address.value,
      bik: suggestion.data.bic,
      corrAccount: suggestion.data.correspondent_account,
    });
    setFormError({
      ...formError,
      bankName: "",
      bankAddress: "",
      bik: "",
      corrAccount: "",
    });
    setValidationResult({
      bankName: "is-valid",
      bankAddress: "is-valid",
      bik: "is-valid",
      corrAccount: "",
    });
  }

  const handleSubmit = () => {
    let result = true;
    let errorMessages = {};
    let validClasses = {};

    if (!formData.companyName)  {
      errorMessages.companyName = 'Это поле объязательно для заполнения';
      validClasses.companyName = 'is-invalid';
      result = false;
    }

    if (formData.inn.length !== 12 && formData.inn.length !== 10) {
      errorMessages.inn = 'ИНН должен быть из 12 цифр';
      validClasses.inn = 'is-invalid';
      result = false;
    }

    if (formData.ogrn.length < 13) {
      errorMessages.ogrn = 'ОГРН или ОГРНИП должны быть 13 и 15 цифр соответсвенно';
      validClasses.ogrn = 'is-invalid';
      result = false;
    }

    if (formData.role === "2") {
      if (!formData.docNumber) {
        errorMessages.docNumber = 'Поле объязательно для заполнения';
        validClasses.docNumber = 'is-invalid';
        result = false;
      }

      if (!formData.docDate) {
        errorMessages.docDate = 'Поле объязательно для заполнения';
        validClasses.docDate = 'is-invalid';
        result = false;
      }
    }

    if (!props.lightForm) {
      if (!formData.bankName) {
        errorMessages.bankName = 'Поле объязательно для заполнения';
        validClasses.bankName = 'is-invalid';
        result = false;
      }

      if (!formData.bankAddress) {
        errorMessages.bankAddress = 'Поле объязательно для заполнения';
        validClasses.bankAddress = 'is-invalid';
        result = false;
      }

      if (!formData.accountNumber) {
        errorMessages.accountNumber = 'Поле объязательно для заполнения';
        validClasses.accountNumber = 'is-invalid';
        result = false;
      }

      if (!formData.bik) {
        errorMessages.bik = 'Поле объязательно для заполнения';
        validClasses.bik = 'is-invalid';
        result = false;
      }

      if (!formData.corrAccount) {
        errorMessages.corrAccount = 'Поле объязательно для заполнения';
        validClasses.corrAccount = 'is-invalid';
        result = false;
      }
    }

    setFormError({...formError, ...errorMessages});
    setValidationResult({...validationResult, ...validClasses});

    if (result) {
      setFormError(inputFields);
      setFormErrorMessage([]);
      setLoading(true);

      /** Преобразуем дату перед отправкой */
      const data = Object.assign({}, formData);
      if (data.docDate) {
        data.docDate = data.docDate.toISOString();
      }

      axios.post('Contragents/Register', formData)
        .then((result) => {
          if (result.status === 200) {
            dispatch(needRegisterPartner(false));
            if (props.reloadPage) {
              window.location.reload();
            } else {
              history.push("/profile/partners");
            }
          } else {
            console.log(result);
          }
        })
        .catch((e) => {
          const {data} = e.response;
          let errors = [];

          Object.keys(data).map(key => {
            const message = data[key];
            errors.push(message);
          });

          setFormErrorMessage(errors);
        });
    } else {
      setFormErrorMessage(['Исправьте ошибки и заполните все поля']);
    }
  }

  return (
    <>
      {loading && <ModalProgressBar />}

      <div className="form">
        <div className="">
          {
            props.showAlert &&
              <div className="form-group">
                <div className="alert alert-custom alert-default" role="alert">
                  <div className="alert-icon">
                  <span className="svg-icon svg-icon-primary svg-icon-xl">
                  </span>
                  </div>
                  <div className="alert-text">
                    Для возможности наполнения корзины и оформления заказа, укажите организацию которую вы представляете.
                  </div>
                </div>
              </div>
          }
          {
            formErrorMessage.length > 0 &&
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {
                    formErrorMessage.map(message => (<>{message}<br /></>))
                  }
                </div>
              </div>
          }

          <>
            <div className="row">
              <label className="col-xl-3"/>
              <div className="col-lg-9 col-xl-9">
                <h5 className="font-weight-bold mb-6">Реквизиты компании:</h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Поиск организации</label>
              <div className="col-lg-9 col-xl-9">
                <div>
                  <SearchHelper
                    onSelectSuggestion={companySuggestionSelected}
                    className="form-control form-control-lg"
                    disabled={manualCompany}
                    url={'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party'}
                    placeholder={"Поиск по названию или ИНН или ОГРН(ИП)"}

                  />
                  <span className="form-text manual" onClick={() => setManualCompany(!manualCompany)}>
                    {manualCompany ? "Заполнить автоматически из поиска" : "Хотите указать реквизиты вручную?"}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Название организации *</label>
              <div className="col-lg-9 col-xl-9">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg ${validationResult.companyName}`}
                    name="companyName"
                    value={formData.companyName}
                    onChange={(e) => setFormData({...formData, companyName: e.target.value})}
                    onBlur={validator.companyNameValidator}
                    disabled={!manualCompany}
                  />
                  {formError.companyName ? (
                    <div className="invalid-feedback">
                      {formError.companyName}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">ИНН *</label>
              <div className="col-lg-9 col-xl-9">
                <div>
                  <input
                    type="number"
                    min="0" step="1"
                    className={`form-control form-control-lg ${validationResult.inn}`}
                    name="inn"
                    value={formData.inn}
                    onChange={(e) => setFormData({...formData, inn: e.target.value})}
                    onBlur={validator.innValidator}
                    disabled={!manualCompany}
                  />
                  {formError.inn ? (
                    <div className="invalid-feedback">
                      {formError.inn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">ОГРН(ИП) *</label>
              <div className="col-lg-9 col-xl-9">
                <div>
                  <input
                    type="number"
                    min="0" step="1"
                    className={`form-control form-control-lg ${validationResult.ogrn}`}
                    name="ogrn"
                    value={formData.ogrn}
                    onChange={(e) => setFormData({...formData, ogrn: e.target.value})}
                    onBlur={validator.ogrnValidator}
                    disabled={!manualCompany}
                  />
                  {formError.ogrn ? (
                    <div className="invalid-feedback">
                      {formError.ogrn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">КПП</label>
              <div className="col-lg-9 col-xl-9">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg ${validationResult.kpp}`}
                    name="kpp"
                    value={formData.kpp}
                    onChange={(e) => setFormData({...formData, kpp: e.target.value})}
                    disabled={!manualCompany}
                  />
                  {formError.kpp ? (
                    <div className="invalid-feedback">
                      {formError.kpp}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Ваша роль</label>
              <div className="col-lg-9 col-xl-9">
                <select
                  name="userRole"
                  className={`form-control`}
                  value={formData.role}
                  onChange={(e) => setFormData({...formData, role: e.target.value})}
                >
                  <option value="1">Единоличный исполнительный орган / Индивидуальный предприниматель</option>
                  <option value="2">Действую на основании доверенности / приказа</option>
                  <option value="0">Без права подписи</option>
                </select>
                <span className="form-text text-muted">Выберите на основании чего вы уполномочены представлять организацию.</span>
              </div>
            </div>
            {
              formData.role === "2" &&
                <>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Номер документа *</label>
                    <div className="col-lg-9 col-xl-4">
                      <div>
                        <input
                          type="text"
                          className={`form-control form-control-lg ${validationResult.docNumber}`}
                          name="docNumber"
                          value={formData.docNumber}
                          onChange={(e) => setFormData({...formData, docNumber: e.target.value})}
                          onBlur={validator.docNumber}
                        />
                        {formError.docNumber ? (
                          <div className="invalid-feedback">
                            {formError.docNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Дата документа *</label>
                    <div className="col-lg-9 col-xl-4">
                      <div>
                        <DatePicker
                          locale="ru"
                          className={`form-control form-control-lg ${validationResult.docDate}`}
                          style={{ width: "100%" }}
                          selected={(formData.docDate && new Date(formData.docDate)) || null}
                          dateFormat="dd.MM.yyyy"
                          onChange={val => {
                            setFormData({...formData, docDate: val})
                          }}
                          customInput={
                            <InputMask
                              mask="99.99.9999"
                              placeholder="01.01.1977"
                              type="text"
                            />
                          }
                        />
                        {formError.docDate ? (
                          <div className="invalid-feedback" style={{display: "block"}}>
                            {formError.docDate}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
            }

            {
              !props.lightForm &&
                <>
                  <div className="separator separator-dashed my-5"/>
                  <div className="row">
                    <label className="col-xl-3"/>
                    <div className="col-lg-9 col-xl-9">
                      <h5 className="font-weight-bold mb-6">Банковские реквизиты:</h5>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Поиск банка</label>
                    <div className="col-lg-9 col-xl-9">
                      <div>
                        <SearchHelper
                          onSelectSuggestion={bankSuggestionSelected}
                          className="form-control"
                          disabled={manualBank}
                          url={'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank'}
                          placeholder={"Поиск по названию банка или БИК"}

                        />
                        <span className="form-text manual" onClick={() => setManualBank(!manualBank)}>
                        {manualBank ? "Заполнить автоматически из поиска" : "Хотите указать реквизиты вручную?"}
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Рассчетный счет *</label>
                    <div className="col-lg-9 col-xl-9">
                      <div>
                        <input
                          type="text"
                          className={`form-control ${validationResult.accountNumber}`}
                          name="accountNumber"
                          value={formData.accountNumber}
                          onChange={(e) => setFormData({...formData, accountNumber: e.target.value})}
                          onBlur={validator.accountNumberValidator}
                        />
                        {formError.accountNumber ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formError.accountNumber}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Наименование банка *</label>
                    <div className="col-lg-9 col-xl-9">
                      <div>
                        <input
                          type="text"
                          className={`form-control ${validationResult.bankName}`}
                          name="bankName"
                          value={formData.bankName}
                          onChange={(e) => setFormData({...formData, bankName: e.target.value})}
                          onBlur={validator.bankNameValidator}
                          disabled={!manualBank}
                        />
                        {formError.bankName ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formError.bankName}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Адрес банка *</label>
                    <div className="col-lg-9 col-xl-9">
                      <div>
                        <input
                          type="text"
                          className={`form-control ${validationResult.bankAddress}`}
                          name="bankAddress"
                          value={formData.bankAddress}
                          onChange={(e) => setFormData({...formData, bankAddress: e.target.value})}
                          onBlur={validator.bankAddressValidator}
                          disabled={!manualBank}
                        />
                        {formError.bankAddress ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formError.bankAddress}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">БИК *</label>
                    <div className="col-lg-9 col-xl-9">
                      <div>
                        <input
                          type="text"
                          className={`form-control ${validationResult.bik}`}
                          name="bik"
                          value={formData.bik}
                          onChange={(e) => setFormData({...formData, bik: e.target.value})}
                          onBlur={validator.bikValidator}
                          disabled={!manualBank}
                        />
                        {formError.bik ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formError.bik}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Кореспондетский счет *</label>
                    <div className="col-lg-9 col-xl-9">
                      <div>
                        <input
                          type="text"
                          className={`form-control ${validationResult.corrAccount}`}
                          name="corrAccount"
                          value={formData.corrAccount}
                          onChange={(e) => setFormData({...formData, corrAccount: e.target.value})}
                          onBlur={validator.corrAccountValidator}
                          disabled={!manualBank}
                        />
                        {formError.corrAccount ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formError.corrAccount}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
            }

          </>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end">
        {
          props?.onHide &&
            <button type="button" className="btn btn-secondary mr-2" onClick={props.onHide}>Сделаю позже...</button>
        }
        <button
          className="btn btn-success mr-2"
          onClick={handleSubmit}
        >
          Добавить
        </button>
      </div>
    </>
  );
}