import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {basketSlice} from "../app/modules/Basket/redux/basketSlice";
import {partnerSlice} from "../app/modules/Partners/redux/partnerSlice";
import {managerSlice} from "../app/modules/Catalog/redux/managerSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  basket: basketSlice.reducer,
  partner: partnerSlice.reducer,
  manager: managerSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
