import {Link} from "react-router-dom";
import React from "react";

export default function RegistrationSuccess() {
  return (
    <>
      {/*begin::Content header*/}
      <div className="position-absolute top-0 right-0 text-right mb-xs-15 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">
          Уже есть аккаунт?
        </span>
        <Link
          to="/auth/login"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          Авторизоваться!
        </Link>
      </div>
      {/*end::Content header*/}
      <div className="d-flex flex-column-fluid flex-center mt-xs-30 mt-10 mt-lg-0">
        <div className="login-form login-signin" style={{maxWidth: '650px'}}>
          {/* begin::Head */}
          <div className="mb-10 mb-lg-20">
            <h3 className="font-size-h1 text-center">
              Поздравляем с регистрацией!
            </h3>
            <br />
            <div className="form-group">
              <div className="alert alert-custom alert-default" role="alert">
                <div className="alert-icon">
                  <span className="svg-icon svg-icon-primary svg-icon-xl">

                  </span>
                </div>
                <div className="alert-text">
                  Для продолжения работы Вам необходимо подвердить указанный email!<br /><br />
                  Мы отправили вам письмо с кодом и сылкой на подтверждение почты.
                  Проверьте свой почтовый ящик, не забудьте проверить папку "Спам", если письма нет и оно долго не приходит, свяжитесь с нами.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}