import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as axios from "axios";
import {useParams, useHistory} from "react-router-dom"

export default function EmailConfirm() {

  const history = useHistory();

  const { code, email } = useParams();
  const [confirmEmail, setConfirmEmail] = useState(decodeURIComponent(email) ?? '')
  const [confirmCode, setConfirmCode] = useState(code ?? '')
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (confirmCode.length === 6) {
      sendCodeHandler();
    }
  }, [confirmCode])

  useEffect(() => {
    if (confirmed) {
      setTimeout(() => {
        history.push('/logout');
      }, 15000)
    }
  }, [confirmed])

  const validator = {
    email: () => {
      let re = /\S+@\S+\.\S+/;
      if (!re.test(confirmEmail)) {
        setErrorEmail('Укажите email соответствующий формату');
        return false;
      } else {
        setErrorEmail('');
        return true;
      }
    },
    code: () => {
      if (!/[0-9]+/.test(confirmCode) || confirmCode.length !== 6) {
        setErrorCode('Код должен содержать 6 цифр');
        return false;
      } else {
        setErrorCode('');
        return true;
      }
    }
  }

  const sendCodeHandler = () => {

    let result = true;
    if (!validator.email()) {
      setErrorEmail('Укажите email соответствующий формату');
      result = false;
    }

    if (!validator.code()) {
      setErrorCode('Код должен содержать только 6 цифр');
      result = false;
    }

    if (result) {
      setLoading(true);

      axios.get(`/Account/CheckEmailCode?email=${confirmEmail}&code=${confirmCode}`)
        .then(() => {
          setLoading(false);
          setConfirmed(true);
        }).catch((e) => {
          if (e.response?.data) {
            const {Identity} = e.response.data;

            setErrorCode(Identity?.[0] ?? "Проверочный код введен не верно");
          } else {
            setErrorCode("Что то пошло не так, обратитесь в тех поддержку");
          }
          setLoading(false);
      })
    }
  }

  return (
    <>
      {/*begin::Content header*/}
      <div className="position-absolute top-0 right-0 text-right mb-xs-15 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">
          Уже есть аккаунт?
        </span>
        <Link
          to="/logout"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          Авторизоваться!
        </Link>
      </div>
      {/*end::Content header*/}

      <div className="d-flex flex-column-fluid flex-center mt-xs-30 mt-10 mt-lg-0">
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              Подтверждение почты
            </h3>
            <p className="text-muted font-weight-bold">
              Введите код отправленный вам на почту, если он не заполнился автоматически при переходе по ссылке
            </p>
          </div>
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {
              confirmed ?
                <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    Почта успешно подтверждена! Вы можете войти в свой аккаунт<br /><br />
                    Вы будете автоматически перенаправлены на страницу авторизации через 15 секунд
                  </div>
                </div> :
                  <>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Email"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6${errorEmail.length > 0 ? ' is-invalid' : ''}`}
                        name="email"
                        value={confirmEmail}
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        onBlur={() => validator.email()}
                      />
                      {errorEmail ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errorEmail}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Code"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6${errorCode.length > 0 ? ' is-invalid' : ''}`}
                        name="code"
                        value={confirmCode}
                        onChange={(e) => setConfirmCode(e.target.value)}
                        onBlur={() => validator.code()}
                      />
                      {errorCode ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errorCode}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="button"
                        disabled={loading}
                        onClick={sendCodeHandler}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Подтвердить</span>
                        {loading && <span className="ml-3 spinner spinner-white"/>}
                      </button>
                    </div>
                  </>
            }
          </form>
        </div>
      </div>
    </>
  );
}