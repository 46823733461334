import React, {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import PinField from "react-pin-field";
import axios from "axios";

export default function CodeRequestModal(props) {

  const [loading, setLoading] = useState(false);
  const pinField = useRef(); //Рефы на поля ввода кода
  const [error, setError] = useState(null);
  const [pin, setPin] = useState('');

  /** Устанавливаем фокус на первое поле для ввода кода */
  useEffect(() => {
    if (pinField) {
        pinField.current.inputs[0].focus();
    }
  },[]);

  /** Отслеживаем повтороное открытие окна */
  useEffect(() => {
    setLoading(false);
  }, [props.show])

  let onCompleteHandler = (code) => {
    if (code.length < 6) {
      setError( {message: 'Укажите код полностью - 6 цифр'});
    } else if (!loading) {
      setLoading(true);

      axios.get("/Account/CheckSMSCode?phone="+props.phone+"&code="+code)
        .then(resp => {
          props.onCodeConfirmed();
        }).catch(e => {
          if (e.response.data) {
            setError({message: e.response.data});
          } else {
            setError({message: "Не верно указан код"});
          }
          if (pinField) {
            pinField.current.inputs.forEach(input => (input.value = ""));
            pinField.current.inputs[0].focus();
            pinField.current.removeAttribute('completed');
          }
          setLoading(false);
      });
    }
  }

  let onChange = (code) => {
    setPin(code);
  }

  return (
    <Modal
      show={true}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h3 id="contained-modal-title-vcenter">
          {props?.title ?? 'Введите код из смс'}
        </h3>
      </Modal.Header>
      <Modal.Body>
        {
          error &&
            <div className="form-group">
              <div className="alert alert-custom alert-default" role="alert">
                <div className="alert-icon">

                </div>
                <div className="alert-text">{error.message}
                </div>
              </div>
            </div>
        }
        <div className="pin-field-container d-flex justify-content-center">
          <PinField
            length={6}
            ref={pinField}
            validate="0123456789"
            inputMode="numeric"
            className="pin-field"
            onChange={onChange}
            onComplete={onCompleteHandler}
          />
        </div>
        <div className="modal-button d-flex justify-content-center mt-5">
          <button
            type={"button"}
            disabled={loading}
            className="btn btn-primary px-9 py-4"
            onClick={() => onCompleteHandler(pin)}
          >
            <span>Отправить</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}